@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
body {
  margin: 0;
  line-height: normal; }

:root {
  /* fonts */
  --font-inter: Inter;
  /* font sizes */
  --font-size-xs: 12px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-77xl: 96px;
  --font-size-47xl: 66px;
  --font-size-27xl: 46px;
  --font-size-17xl: 36px;
  --font-size-12xl: 31px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-7xl: 26px;
  /* Colors */
  --color-gray-100: #202022;
  --color-gray-200: rgba(255, 255, 255, 0.9);
  --color-gray-300: rgba(32, 32, 34, 0.9);
  --color-gray-400: rgba(0, 0, 0, 0);
  --color-gray-500: rgba(255, 255, 255, 0.3);
  --color-darkslategray: #323235;
  --color-red: #db0c0c;
  --color-white: #fff;
  --color-silver: #c5c5c5;
  --color-silver-100: rgba(197, 197, 197, 0);
  --color-darkorchid: #b61db9;
  --color-forestgreen: #1db954;
  --color-darkorange: #f7931a;
  --color-gainsboro: rgba(217, 217, 217, 0);
  --color-lightgray: #d5d5d5;
  /* Gaps */
  --gap-9xs: 4px;
  --gap-34xl: 53px;
  --gap-643xl: 662px;
  --gap-xl: 20px;
  --gap-0: 0px;
  --gap-3xs: 10px;
  --gap-17xl: 36px;
  --gap-11xl: 30px;
  --gap-2xs: 11px;
  --gap-16xl: 35px;
  /* Paddings */
  --padding-3xs: 10px;
  --padding-131xl: 150px;
  --padding-64xl: 83px;
  --padding-241xl: 260px;
  --padding-mid: 17px;
  --padding-80xl: 99px;
  --padding-10xl: 29px;
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-8xs: 5px;
  --padding-lgi: 19px;
  --padding-101xl: 120px;
  --padding-46xl: 65px;
  --padding-6xs: 7px;
  /* border radiuses */
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-6xl: 25px;
  --br-8xs: 5px;
  --br-11xl: 30px; }

html {
  height: 100%; }

html body {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #202022; }

html body footer {
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #202022;
  padding-top: 35px;
  padding-bottom: 70px; }

html body footer > div {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-base);
  font-family: var(--font-inter);
  width: 100vw;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  align-items: start;
  text-decoration: none; }

@media (min-width: 600px) {
  html body footer > div {
    grid-template-columns: repeat(3, 1fr); } }

html body footer > div > div > div:first-child {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 22px; }

@media (max-width: 599px) {
  html body footer > div > div > div:first-child {
    margin-top: 35px; } }

html body footer > div > div > div:not(:first-child) {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6); }

html body footer > div > div > div:not(:first-child) a {
  color: rgba(255, 255, 255, 0.8); }

html body footer > div > div > div:not(:first-child) a:hover {
  color: white; }

html body footer > div > div:first-of-type {
  text-align: center; }

html body footer > div > div:last-of-type {
  text-align: center; }

html body footer > div .center-text {
  text-align: center; }

.spinner {
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.satTypeTitle,
.satTypeTitle1,
.satTypeTitle2 {
  position: relative;
  display: flex;
  align-items: center;
  width: 219px;
  height: 24px;
  flex-shrink: 0; }

.satTypeTitle,
.satTypeTitle1,
.satTypeTitle2 {
  font-family: var(--font-inter);
  text-align: center;
  justify-content: center;
  width: 216px;
  height: 19px; }

.satTypeTitle {
  font-size: var(--font-size-xl);
  font-weight: 800; }

.satTypeTitle1 {
  font-size: var(--font-size-base);
  font-weight: 500; }

.satTypeTitle2 {
  font-size: var(--font-size-sm); }

.feeSelector {
  cursor: pointer;
  border: 2px solid var(--color-silver-100);
  padding: var(--padding-lgi) 0;
  background-color: transparent;
  border-radius: var(--br-3xs);
  background: linear-gradient(180deg, #f7931a, #f4a84d);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-2xs); }

.satTypeTitle4,
.satTypeTitle5 {
  position: relative;
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  height: 19px;
  flex-shrink: 0; }

.satTypeTitle4 {
  font-size: var(--font-size-base);
  font-weight: 500; }

.satTypeTitle5 {
  font-size: var(--font-size-sm); }

.feeSelector1,
.feeSelector2,
.feeSelectorContainer {
  align-items: flex-start;
  justify-content: flex-start; }

.feeSelector1,
.feeSelector2 {
  cursor: pointer;
  border: 2px solid var(--color-silver-100);
  padding: var(--padding-lgi) 0;
  background-color: var(--color-gray-500);
  border-radius: var(--br-3xs);
  flex-direction: column;
  gap: var(--gap-2xs); }

.feeSelectorContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 10pt;
  align-items: stretch; }

.feeSelectorContainer > button.feeOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background-color: var(--color-silver);
  border: 2px solid var(--color-silver-100);
  border-radius: 10px;
  overflow: hidden;
  gap: 5pt;
  padding-bottom: 10pt;
  color: black;
  /* Chrome */
  /* Firefox */ }

.feeSelectorContainer > button.feeOption.active {
  background: linear-gradient(180deg, #f7931a, #f4a84d);
  color: white; }

.feeSelectorContainer > button.feeOption:not(.active) {
  cursor: pointer; }

.feeSelectorContainer > button.feeOption > div.satTypeTitle {
  padding-top: 10pt;
  padding-bottom: 2pt; }

.feeSelectorContainer > button.feeOption > div.satTypeTitle.active {
  color: white; }

.feeSelectorContainer > button.feeOption input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  border-radius: 3px; }

.feeSelectorContainer > button.feeOption input[type=range]::-webkit-slider-runnable-track {
  background: white;
  height: 5px;
  border-radius: 3px; }

.feeSelectorContainer > button.feeOption input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: black;
  /* Make sure this variable is defined, or use a color like #ff9900 */
  border: 2px solid white;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin-top: -5px; }

.feeSelectorContainer > button.feeOption input[type=range]::-moz-range-track {
  background: white;
  height: 5px;
  border-radius: 3px; }

.feeSelectorContainer > button.feeOption input[type=range]::-moz-range-thumb {
  background: black;
  /* Make sure this variable is defined, or use a color like #ff9900 */
  border: 2px solid white;
  border-radius: 50%;
  height: 15px;
  width: 15px; }

.timesAndUsd {
  position: relative;
  font-size: var(--font-size-base);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  flex-shrink: 0;
  margin-bottom: 15px; }

.selectFeeParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  width: 100%; }

.selectFeeContainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-inter); }

@media screen and (max-width: 1200px) {
  .feeSelectorContainer {
    gap: var(--gap-xl);
    align-items: center;
    justify-content: center; }
  .selectFeeContainer {
    align-items: flex-start;
    justify-content: flex-start; } }

@media screen and (max-width: 430px) {
  .satTypeTitle {
    font-size: var(--font-size-mini); }
  .satTypeTitle1,
  .satTypeTitle2 {
    font-size: var(--font-size-sm);
    text-align: center; }
  .satTypeTitle2 {
    font-size: var(--font-size-2xs); }
  .feeSelector {
    width: auto;
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-8xs);
    padding-right: var(--padding-8xs);
    box-sizing: border-box; }
  .satTypeTitle4 {
    font-size: var(--font-size-sm); }
  .satTypeTitle5 {
    font-size: var(--font-size-2xs); }
  .feeSelector1,
  .feeSelector2 {
    align-items: center;
    justify-content: center;
    padding-left: var(--padding-8xs);
    box-sizing: border-box; }
  .feeSelector1 {
    padding-right: 5px; }
  .feeSelector2 {
    padding-right: var(--padding-8xs); }
  .feeSelectorContainer {
    align-self: stretch;
    width: auto;
    flex: 1;
    align-items: center;
    justify-content: flex-start; }
  .timesAndUsd {
    font-size: var(--font-size-sm); }
  .selectFeeContainer,
  .selectFeeParent,
  .timesAndUsd {
    align-self: stretch;
    width: auto; }
  .selectFeeContainer {
    height: auto; } }
