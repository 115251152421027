@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes pulsate {
  0% {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out; }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in; }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out; }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in; }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

.every {
  color: var(--color-white); }

.satoshi {
  color: var(--color-darkorange); }

.everySatoshi {
  margin: 0; }

.story {
  font-weight: 800;
  font-family: var(--font-inter);
  color: var(--color-darkorange); }

.title1 {
  position: relative; }

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; }

@media screen and (max-width: 600px) {
  .buttonsContainer {
    flex-direction: column;
    align-items: center; }
  .inscribeButton {
    height: initial !important;
    margin: 0px !important; }
  .center {
    gap: var(--gap-25) !important; } }

.inscribeNow,
.titleLabel {
  display: flex;
  align-items: center;
  justify-content: center; }

.titleLabel {
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  opacity: 0; }

.titleLabel.animate {
  animation: 1s ease 0s 1 normal forwards fade-in-top; }

.inscribeNow {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-5xl);
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: center;
  width: 280px;
  height: 60px; }

.inscribeNowWrapper {
  align-self: stretch;
  position: relative;
  border-radius: var(--br-11xl);
  background-color: var(--color-darkorange);
  height: 60px;
  overflow: hidden;
  flex-shrink: 0; }

.inscribeNowWrapper:hover {
  box-shadow: 0 4px 4px rgba(255, 253, 253, 0.25);
  animation: 1s ease-in-out 0s infinite reverse none pulsate;
  opacity: 1; }

.inscribeButton,
.inscribeButtonInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.inscribeButtonInner {
  align-self: stretch;
  flex: 1;
  padding: var(--padding-3xs);
  opacity: 0; }

.inscribeButtonInner.animate {
  animation: 0.4s ease-out 0.2s 1 normal forwards fade-in; }

.inscribeButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  width: 300px;
  height: 120px;
  margin-right: 20px; }

.inscribeButton:last-child {
  margin-right: 0; }

.inscribeButton:hover {
  border-radius: 0; }

.inscribeOrdinalsOnContainer1 {
  line-break: anywhere;
  width: 100%;
  font-size: var(--font-size-12xl); }

.inscribeOrdinalsOnContainer {
  position: relative;
  font-size: var(--font-size-13xl);
  display: flex;
  align-items: center;
  width: 932px;
  height: 64px;
  flex-shrink: 0; }

.center,
.title {
  display: flex;
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs); }

.title {
  height: 564px;
  padding: var(--padding-3xs) 0 var(--padding-64xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: center;
  font-size: var(--font-size-77xl);
  color: var(--color-white);
  font-family: var(--font-inter); }

@media screen and (max-width: 960px) {
  .title1 {
    font-size: var(--font-size-47xl); }
  .inscribeButton {
    display: flex; } }

@media screen and (max-width: 430px) {
  .title1 {
    font-size: var(--font-size-27xl); }
  .inscribeOrdinalsOnContainer {
    width: 375px; }
  .title {
    height: 480px; }
  .inscribeOrdinalsOnContainer1 {
    margin-top: 70px; }
  .buttonsContainer {
    margin-top: 30px; } }

@media screen and (max-width: 350px) {
  .inscribeOrdinalsOnContainer {
    font-size: var(--font-size-9xl);
    width: 325px; } }
