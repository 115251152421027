@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
body {
  margin: 0;
  line-height: normal; }

:root {
  /* fonts */
  --font-inter: Inter;
  /* font sizes */
  --font-size-xs: 12px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-77xl: 96px;
  --font-size-47xl: 66px;
  --font-size-27xl: 46px;
  --font-size-16xl: 42px;
  --font-size-17xl: 36px;
  --font-size-12xl: 31px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-7xl: 26px;
  /* Colors */
  --color-gray-100: #202022;
  --color-gray-200: rgba(255, 255, 255, 0.9);
  --color-gray-300: rgba(32, 32, 34, 0.9);
  --color-gray-400: rgba(0, 0, 0, 0);
  --color-gray-500: rgba(255, 255, 255, 0.3);
  --color-darkslategray: #323235;
  --color-red: #db0c0c;
  --color-white: #fff;
  --color-silver: #c5c5c5;
  --color-silver-100: rgba(197, 197, 197, 0);
  --color-darkorchid: #b61db9;
  --color-forestgreen: #1db954;
  --color-darkorange: #f7931a;
  --color-gainsboro: rgba(217, 217, 217, 0);
  --color-lightgray: #d5d5d5;
  /* Gaps */
  --gap-9xs: 4px;
  --gap-34xl: 53px;
  --gap-643xl: 662px;
  --gap-xl: 20px;
  --gap-0: 0px;
  --gap-3xs: 10px;
  --gap-17xl: 36px;
  --gap-11xl: 30px;
  --gap-2xs: 11px;
  --gap-16xl: 35px;
  /* Paddings */
  --padding-3xs: 10px;
  --padding-131xl: 150px;
  --padding-64xl: 83px;
  --padding-241xl: 260px;
  --padding-mid: 17px;
  --padding-80xl: 99px;
  --padding-10xl: 29px;
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-8xs: 5px;
  --padding-lgi: 19px;
  --padding-101xl: 120px;
  --padding-46xl: 65px;
  --padding-6xs: 7px;
  /* border radiuses */
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-6xl: 25px;
  --br-8xs: 5px;
  --br-11xl: 30px; }

html {
  height: 100%; }

html body {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #202022; }

html body footer {
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #202022;
  padding-top: 35px;
  padding-bottom: 70px; }

html body footer > div {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-base);
  font-family: var(--font-inter);
  width: 100vw;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  align-items: start;
  text-decoration: none; }

@media (min-width: 600px) {
  html body footer > div {
    grid-template-columns: repeat(3, 1fr); } }

html body footer > div > div > div:first-child {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 22px; }

@media (max-width: 599px) {
  html body footer > div > div > div:first-child {
    margin-top: 35px; } }

html body footer > div > div > div:not(:first-child) {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6); }

html body footer > div > div > div:not(:first-child) a {
  color: rgba(255, 255, 255, 0.8); }

html body footer > div > div > div:not(:first-child) a:hover {
  color: white; }

html body footer > div > div:first-of-type {
  text-align: center; }

html body footer > div > div:last-of-type {
  text-align: center; }

html body footer > div .center-text {
  text-align: center; }

.spinner {
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.sliderFile1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-11xl);
  background: linear-gradient(180deg, #f7931a, #f3ab54); }

.file,
.sliderFile,
.sliderFile1 {
  width: 140px;
  height: 50px; }

.file {
  position: absolute;
  top: 0;
  left: 0;
  font-size: var(--font-size-5xl);
  font-weight: 800;
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; }

.sliderFile {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative; }

.sliderFile2 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-11xl);
  background: linear-gradient(180deg, #f7931a, #f3ab54);
  width: 140px;
  height: 50px;
  display: none; }

.ordinalTypeSlider {
  border-radius: var(--br-11xl);
  background-color: var(--color-gainsboro);
  border: 2px solid var(--color-silver);
  display: flex;
  flex-direction: row;
  padding: var(--padding-8xs);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs); }

.satTypeDescription,
.satTypePrice,
.satTypeTitle {
  position: relative;
  display: flex;
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: left;
  align-items: center;
  width: 100px;
  height: 19px;
  flex-shrink: 0; }

.satTypeTitle {
  font-size: var(--font-size-mini);
  font-weight: 800; }

.satTypeDescription,
.satTypePrice {
  font-size: var(--font-size-sm); }

.satTypeDescription {
  font-size: var(--font-size-2xs);
  font-weight: 600;
  color: var(--color-gray-200); }

.satTypeSelector1 {
  cursor: pointer;
  border: 2px solid var(--color-silver-100);
  padding: var(--padding-8xs);
  background-color: transparent;
  border-radius: var(--br-8xs);
  background: linear-gradient(180deg, #f7931a, #f4a84d);
  box-sizing: border-box;
  height: 67px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; }

.satTypeDescription1,
.satTypePrice1,
.satTypeTitle1 {
  position: relative;
  display: flex;
  font-family: var(--font-inter);
  color: var(--color-gray-100);
  text-align: left;
  align-items: center;
  width: 100px;
  height: 19px;
  flex-shrink: 0; }

.satTypeTitle1 {
  font-size: var(--font-size-mini);
  font-weight: 800; }

.satTypeDescription1,
.satTypePrice1 {
  font-size: var(--font-size-sm); }

.satTypeDescription1 {
  font-size: var(--font-size-2xs);
  font-weight: 600;
  color: var(--color-gray-300); }

.satTypeSelector2 {
  cursor: pointer;
  border: 2px solid var(--color-silver-100);
  padding: var(--padding-8xs);
  background-color: var(--color-silver);
  border-radius: var(--br-8xs);
  box-sizing: border-box;
  height: 67px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; }

.satTypeSelector,
.satTypes {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start; }

.satTypes {
  width: 100%;
  max-width: 660px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px; }

@media (max-width: 899px) {
  .satTypes {
    grid-template-columns: repeat(3, 1fr); } }

.satTypeSelector {
  border-radius: var(--br-3xs);
  background-color: var(--color-gainsboro);
  border: 2px solid var(--color-silver);
  padding: var(--padding-6xs) var(--padding-8xs);
  width: 100%;
  box-sizing: border-box; }

.uploadLabel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  font-size: var(--font-size-7xl); }

.dragDropLabel {
  font-size: 13pt;
  font-weight: 600; }

.jpgPngGif {
  font-size: 11pt;
  margin: 0; }

.supportedFiletypeLabelTxt {
  line-break: anywhere;
  width: 100%; }

.supportedFiletypeLabel,
.uploadContainer {
  width: 100%;
  display: flex;
  align-items: center; }

.supportedFiletypeLabel {
  position: relative;
  font-size: var(--font-size-xl);
  font-weight: 500;
  color: var(--color-lightgray);
  height: 68px;
  flex-shrink: 0; }

.uploadContainer {
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  border: 4px dashed var(--color-white);
  box-sizing: border-box;
  height: 350px;
  flex-direction: column;
  padding: var(--padding-46xl) 0;
  justify-content: space-between;
  width: calc(100% - 8px);
  max-width: 942px;
  font-size: var(--font-size-base);
  padding-left: 20pt;
  padding-right: 20pt; }

.uploadContainerText {
  display: flex;
  align-items: center;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  height: 350px;
  flex-direction: column;
  padding: var(--padding-46xl) 0;
  justify-content: space-between;
  font-size: var(--font-size-base);
  border: 2pt solid var(--color-white);
  width: calc(100% - 4pt);
  max-width: 944.66667px;
  box-sizing: border-box;
  padding: 10pt;
  height: 150pt;
  border-radius: 20px;
  outline: none;
  font-size: 15pt;
  padding-left: 15pt;
  padding-right: 15pt;
  color: #f7931a; }

.uploadContainerUninscribed {
  display: flex;
  align-items: center;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-400);
  height: 350px;
  flex-direction: column;
  padding: var(--padding-46xl) 0;
  justify-content: space-between;
  font-size: var(--font-size-base);
  height: unset;
  text-align: center;
  border: 2pt solid var(--color-white);
  width: calc(100% - 4pt);
  max-width: 944.66667px;
  box-sizing: border-box;
  padding: 10pt;
  border-radius: 50px;
  outline: none;
  font-size: 15pt;
  padding-left: 15pt;
  padding-right: 15pt;
  color: #f7931a; }

input[type=number] {
  -mox-appearance: textfield; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0; }

.uninscribedContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%; }

.uninscribedCountButton {
  border: 2px solid white !important;
  border-radius: 50px;
  height: 3rem;
  width: 3rem;
  background-color: transparent;
  color: white !important; }

.satsProvidedBy {
  position: relative;
  display: flex;
  align-items: center;
  width: 136px;
  height: 50px;
  flex-shrink: 0; }

.image1, button#satRepublicButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  border-radius: var(--br-xl);
  width: 40px;
  height: 40px;
  background-image: url(/public/image1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top; }

.satsProvidedByParent {
  width: 176px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.satrarity {
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-101xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-base); }

.payButtonChild {
  position: relative;
  border-radius: var(--br-6xl);
  background-color: var(--color-darkorange);
  max-width: 950px;
  height: 50px; }

.button:disabled {
  cursor: not-allowed !important; }

button:disabled div {
  background-color: darkgray !important; }

.inscriberUi,
.payButton,
.uploadPay {
  display: flex;
  align-items: center;
  justify-content: center; }

.uploadPay {
  position: relative;
  font-size: var(--font-size-xl);
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: center;
  height: 50px;
  flex-shrink: 0;
  margin-top: -50px; }

.payButton {
  cursor: pointer;
  border: 0;
  background-color: transparent;
  height: 50px;
  width: 100%;
  padding: 0; }

.payButtonChild, .uploadPay {
  width: 100%; }

.inscriberUi,
.payButton {
  flex-direction: column; }

.inscriberUi {
  gap: var(--gap-16xl);
  text-align: center;
  font-size: var(--font-size-17xl);
  color: var(--color-white);
  font-family: var(--font-inter);
  width: 100%; }

.satAmount {
  color: var(--color-darkorange); }

.uninscribedHeader {
  font-size: var(--font-size-xl);
  font-weight: bold; }

@media screen and (max-width: 430px) {
  .satTypes {
    width: 300px; }
  .satTypeSelector {
    align-self: stretch;
    width: auto;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding-top: var(--padding-6xs);
    padding-right: var(--padding-8xs);
    box-sizing: border-box; }
  .dragDropLabel {
    font-size: var(--font-size-sm); }
  .supportedFiletypeLabel {
    font-size: var(--font-size-xs); }
  .uploadContainer {
    align-self: stretch;
    width: auto;
    flex: 1; }
  .payButton {
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 0;
    box-sizing: border-box;
    max-height: 50px; }
  .inscriberUi {
    align-self: stretch;
    width: auto; } }

.tabContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  border: 2px solid white;
  width: 95%;
  padding: 0.75rem; }

.tabs {
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 99px; }

.tabs * {
  z-index: 2; }

input[type="radio"] {
  display: none; }

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 25%;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  font-size: var(--font-size-xl);
  font-weight: 800;
  font-family: var(--font-inter);
  color: var(--color-white);
  z-index: 3; }

input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0); }

input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%); }

input[id="radio-3"]:checked ~ .glider {
  transform: translateX(200%); }

input[id="radio-4"]:checked ~ .glider {
  transform: translateX(300%); }

.glider {
  position: absolute;
  display: flex;
  height: 40px;
  width: 25%;
  background: linear-gradient(180deg, #f7931a, #f3ab54);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out; }

@media (max-width: 600px) {
  .tab {
    font-size: var(--font-size-xs); } }

@media (max-width: 400px) {
  .tab {
    font-size: var(--font-size-xs); } }

@media (max-width: 430px) {
  .tabs {
    transform: scale(1); }
  .uploadContainer {
    flex: none; }
  .satTypes {
    width: 100%; } }
