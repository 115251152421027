.modalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Dim background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensures that the modal is on top */
}

.modalContent {
	position: relative;
	max-width: 100%;
	max-height: 100%;
	overflow: auto;
}
