@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
body {
  margin: 0;
  line-height: normal; }

:root {
  /* fonts */
  --font-inter: Inter;
  /* font sizes */
  --font-size-xs: 12px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-77xl: 96px;
  --font-size-47xl: 66px;
  --font-size-27xl: 46px;
  --font-size-17xl: 36px;
  --font-size-12xl: 31px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-7xl: 26px;
  /* Colors */
  --color-gray-100: #202022;
  --color-gray-200: rgba(255, 255, 255, 0.9);
  --color-gray-300: rgba(32, 32, 34, 0.9);
  --color-gray-400: rgba(0, 0, 0, 0);
  --color-gray-500: rgba(255, 255, 255, 0.3);
  --color-darkslategray: #323235;
  --color-red: #db0c0c;
  --color-white: #fff;
  --color-silver: #c5c5c5;
  --color-silver-100: rgba(197, 197, 197, 0);
  --color-darkorchid: #b61db9;
  --color-forestgreen: #1db954;
  --color-darkorange: #f7931a;
  --color-gainsboro: rgba(217, 217, 217, 0);
  --color-lightgray: #d5d5d5;
  /* Gaps */
  --gap-9xs: 4px;
  --gap-34xl: 53px;
  --gap-643xl: 662px;
  --gap-xl: 20px;
  --gap-0: 0px;
  --gap-3xs: 10px;
  --gap-17xl: 36px;
  --gap-11xl: 30px;
  --gap-2xs: 11px;
  --gap-16xl: 35px;
  /* Paddings */
  --padding-3xs: 10px;
  --padding-131xl: 150px;
  --padding-64xl: 83px;
  --padding-241xl: 260px;
  --padding-mid: 17px;
  --padding-80xl: 99px;
  --padding-10xl: 29px;
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-8xs: 5px;
  --padding-lgi: 19px;
  --padding-101xl: 120px;
  --padding-46xl: 65px;
  --padding-6xs: 7px;
  /* border radiuses */
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-6xl: 25px;
  --br-8xs: 5px;
  --br-11xl: 30px; }

html {
  height: 100%; }

html body {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #202022; }

html body footer {
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #202022;
  padding-top: 35px;
  padding-bottom: 70px; }

html body footer > div {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-base);
  font-family: var(--font-inter);
  width: 100vw;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  align-items: start;
  text-decoration: none; }

@media (min-width: 600px) {
  html body footer > div {
    grid-template-columns: repeat(3, 1fr); } }

html body footer > div > div > div:first-child {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 22px; }

@media (max-width: 599px) {
  html body footer > div > div > div:first-child {
    margin-top: 35px; } }

html body footer > div > div > div:not(:first-child) {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6); }

html body footer > div > div > div:not(:first-child) a {
  color: rgba(255, 255, 255, 0.8); }

html body footer > div > div > div:not(:first-child) a:hover {
  color: white; }

html body footer > div > div:first-of-type {
  text-align: center; }

html body footer > div > div:last-of-type {
  text-align: center; }

html body footer > div .center-text {
  text-align: center; }

.spinner {
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.div,
.inscriptionSats,
.x10000Sats {
  position: relative;
  display: flex;
  align-items: center;
  height: 39px;
  flex-shrink: 0; }

.inscriptionSats {
  text-align: left;
  width: 160px; }

.div,
.x10000Sats {
  width: 165px; }

.div {
  width: 148px; }

.inscriptionSatFee {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-17xl); }

.div1,
.networkFee,
.sats {
  position: relative;
  display: flex;
  align-items: center;
  height: 39px;
  flex-shrink: 0; }

.networkFee {
  text-align: left;
  width: 160px; }

.div1,
.sats {
  width: 165px; }

.div1 {
  width: 148px; }

.inscriptionSatFee1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-17xl); }

.containerChild {
  position: relative;
  border-top: 2px solid var(--color-gray-500);
  box-sizing: border-box;
  height: 2px; }

.baseServiceFee {
  position: relative;
  display: flex;
  text-align: left;
  align-items: center;
  width: 160px;
  height: 39px;
  flex-shrink: 0; }

.inscriptionSatFee4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-17xl); }

.bitcoinReceiveAddress,
.invoiceContainer {
  align-self: stretch;
  flex-shrink: 0;
  display: flex;
  box-sizing: border-box; }

.backButton {
  width: 80pt;
  height: 20pt;
  text-align: center;
  padding-top: 3pt;
  padding-bottom: 3pt;
  border-radius: 20pt;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  color: var(--color-lightgray);
  font-weight: normal;
  font-size: 13pt;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer; }

.backButton:hover {
  background-color: #555555; }

.couponCode {
  border: 0;
  font-family: var(--font-inter);
  font-size: 13pt;
  background-color: #3a3a3c;
  border-radius: var(--br-6xl);
  height: 20pt;
  flex-direction: row;
  padding: var(--padding-mini) 35px;
  width: 86pt;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none;
  text-align: center;
  padding-top: 3pt;
  padding-bottom: 3pt;
  color: var(--color-lightgray);
  font-weight: normal;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  cursor: text; }

.pizzaCouponCode {
  color: white;
  font-family: var(--font-inter);
  font-weight: bold;
  font-size: 13pt;
  border-color: #f7931a;
  border-radius: var(--br-6xl);
  border-width: 2px;
  border-style: solid;
  height: 20pt;
  flex-direction: row;
  padding: var(--padding-mini) 35px;
  width: 116pt;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none;
  text-align: center;
  padding-top: 3pt;
  padding-bottom: 3pt;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  cursor: pointer; }

.pizzaCouponCodeLink {
  font-weight: bold;
  color: #f7931a; }

.invoiceContainer {
  border-radius: var(--br-3xs);
  background-color: rgba(255, 255, 255, 0.09);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25pt;
  padding-left: 50pt;
  padding-right: 50pt; }

@media (max-width: 899px) {
  .invoiceContainer {
    padding-left: 25pt;
    padding-right: 25pt; } }

.invoiceContainer > table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse; }

.invoiceContainer > table tr > td {
  font-size: 12pt;
  padding-bottom: 15pt;
  font-weight: bold; }

@media (max-width: 899px) {
  .invoiceContainer > table tr > td {
    font-size: 11pt; } }

.invoiceContainer > table tr > td:first-child {
  text-align: left; }

.invoiceContainer > table tr > td:nth-child(2) {
  text-align: right;
  white-space: nowrap; }

.invoiceContainer > table tr > td:last-child {
  padding-left: 10pt;
  text-align: right;
  white-space: nowrap; }

.invoiceContainer > table tr:not(.total) > td:first-child {
  font-weight: normal; }

.invoiceContainer > table tr.total > td {
  border-top: 1px solid white;
  padding-top: 15pt;
  padding-bottom: 0; }

.bitcoinReceiveAddress {
  border: 0;
  font-family: var(--font-inter);
  font-size: var(--font-size-base);
  background-color: #3a3a3c;
  border-radius: var(--br-6xl);
  height: 50px;
  flex-direction: row;
  padding: var(--padding-mini) 35px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none; }

.payButtonChild,
.submitPayment {
  position: relative;
  width: 100%;
  height: 50px; }

.payButtonChild {
  border-radius: var(--br-6xl);
  background-color: var(--color-darkorange); }

.submitPayment {
  font-size: var(--font-size-xl);
  display: flex;
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: -50px; }

.inscribeFeeContainer,
.payButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.inscribeTopSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.payButton {
  width: 100%;
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-self: stretch;
  align-items: center; }

.inscribeFeeContainer {
  position: relative;
  border-radius: var(--br-xl);
  background: linear-gradient(180deg, rgba(32, 32, 34, 0.9), #202022);
  box-sizing: border-box;
  gap: 15px;
  max-width: 100%;
  max-height: 100%;
  text-align: right;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-inter);
  align-items: center;
  justify-content: flex-start; }
