@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
body {
  margin: 0;
  line-height: normal; }

:root {
  /* fonts */
  --font-inter: Inter;
  /* font sizes */
  --font-size-xs: 12px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-77xl: 96px;
  --font-size-47xl: 66px;
  --font-size-27xl: 46px;
  --font-size-16xl: 42px;
  --font-size-17xl: 36px;
  --font-size-12xl: 31px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-7xl: 26px;
  /* Colors */
  --color-gray-100: #202022;
  --color-gray-200: rgba(255, 255, 255, 0.9);
  --color-gray-300: rgba(32, 32, 34, 0.9);
  --color-gray-400: rgba(0, 0, 0, 0);
  --color-gray-500: rgba(255, 255, 255, 0.3);
  --color-darkslategray: #323235;
  --color-red: #db0c0c;
  --color-white: #fff;
  --color-silver: #c5c5c5;
  --color-silver-100: rgba(197, 197, 197, 0);
  --color-darkorchid: #b61db9;
  --color-forestgreen: #1db954;
  --color-darkorange: #f7931a;
  --color-gainsboro: rgba(217, 217, 217, 0);
  --color-lightgray: #d5d5d5;
  /* Gaps */
  --gap-9xs: 4px;
  --gap-34xl: 53px;
  --gap-643xl: 662px;
  --gap-xl: 20px;
  --gap-0: 0px;
  --gap-3xs: 10px;
  --gap-17xl: 36px;
  --gap-11xl: 30px;
  --gap-2xs: 11px;
  --gap-16xl: 35px;
  /* Paddings */
  --padding-3xs: 10px;
  --padding-131xl: 150px;
  --padding-64xl: 83px;
  --padding-241xl: 260px;
  --padding-mid: 17px;
  --padding-80xl: 99px;
  --padding-10xl: 29px;
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-8xs: 5px;
  --padding-lgi: 19px;
  --padding-101xl: 120px;
  --padding-46xl: 65px;
  --padding-6xs: 7px;
  /* border radiuses */
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-6xl: 25px;
  --br-8xs: 5px;
  --br-11xl: 30px; }

html {
  height: 100%; }

html body {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #202022; }

html body footer {
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #202022;
  padding-top: 35px;
  padding-bottom: 70px; }

html body footer > div {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-base);
  font-family: var(--font-inter);
  width: 100vw;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  align-items: start;
  text-decoration: none; }

@media (min-width: 600px) {
  html body footer > div {
    grid-template-columns: repeat(3, 1fr); } }

html body footer > div > div > div:first-child {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 22px; }

@media (max-width: 599px) {
  html body footer > div > div > div:first-child {
    margin-top: 35px; } }

html body footer > div > div > div:not(:first-child) {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6); }

html body footer > div > div > div:not(:first-child) a {
  color: rgba(255, 255, 255, 0.8); }

html body footer > div > div > div:not(:first-child) a:hover {
  color: white; }

html body footer > div > div:first-of-type {
  text-align: center; }

html body footer > div > div:last-of-type {
  text-align: center; }

html body footer > div .center-text {
  text-align: center; }

.spinner {
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

p.amount {
  font-size: 17pt; }

p.amount > .satAmount {
  color: #f7931a; }

span.receiveAddress {
  max-width: 950px;
  text-overflow: ellipsis; }

span.receiveAddress > b {
  font-weight: bold; }

@media (max-width: 899px) {
  span.receiveAddress {
    font-size: 10pt; } }

@media (max-width: 899px) {
  p.disclaimer {
    font-size: 10pt; } }

.transcations {
  maxWidth: "100vw";
  margin-top: 30pt; }

.transcations table {
  width: 100%;
  border-spacing: 0;
  font-size: 15pt; }

.transcations table th {
  padding-bottom: 10pt; }

@media (max-width: 899px) {
  .transcations table tr {
    font-size: 12pt; } }

.transcations table tr > td {
  font-size: 12pt;
  padding-bottom: 5pt;
  text-align: center; }

@media (max-width: 899px) {
  .transcations table tr > td {
    font-size: 12pt; } }
