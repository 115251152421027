@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
body {
  margin: 0;
  line-height: normal; }

:root {
  /* fonts */
  --font-inter: Inter;
  /* font sizes */
  --font-size-xs: 12px;
  --font-size-mini: 15px;
  --font-size-base: 16px;
  --font-size-xl: 20px;
  --font-size-77xl: 96px;
  --font-size-47xl: 66px;
  --font-size-27xl: 46px;
  --font-size-16xl: 42px;
  --font-size-17xl: 36px;
  --font-size-12xl: 31px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-2xs: 11px;
  --font-size-5xl: 24px;
  --font-size-7xl: 26px;
  /* Colors */
  --color-gray-100: #202022;
  --color-gray-200: rgba(255, 255, 255, 0.9);
  --color-gray-300: rgba(32, 32, 34, 0.9);
  --color-gray-400: rgba(0, 0, 0, 0);
  --color-gray-500: rgba(255, 255, 255, 0.3);
  --color-darkslategray: #323235;
  --color-red: #db0c0c;
  --color-white: #fff;
  --color-silver: #c5c5c5;
  --color-silver-100: rgba(197, 197, 197, 0);
  --color-darkorchid: #b61db9;
  --color-forestgreen: #1db954;
  --color-darkorange: #f7931a;
  --color-gainsboro: rgba(217, 217, 217, 0);
  --color-lightgray: #d5d5d5;
  /* Gaps */
  --gap-9xs: 4px;
  --gap-34xl: 53px;
  --gap-643xl: 662px;
  --gap-xl: 20px;
  --gap-0: 0px;
  --gap-3xs: 10px;
  --gap-17xl: 36px;
  --gap-11xl: 30px;
  --gap-2xs: 11px;
  --gap-16xl: 35px;
  /* Paddings */
  --padding-3xs: 10px;
  --padding-131xl: 150px;
  --padding-64xl: 83px;
  --padding-241xl: 260px;
  --padding-mid: 17px;
  --padding-80xl: 99px;
  --padding-10xl: 29px;
  --padding-11xl: 30px;
  --padding-mini: 15px;
  --padding-8xs: 5px;
  --padding-lgi: 19px;
  --padding-101xl: 120px;
  --padding-46xl: 65px;
  --padding-6xs: 7px;
  /* border radiuses */
  --br-xl: 20px;
  --br-3xs: 10px;
  --br-6xl: 25px;
  --br-8xs: 5px;
  --br-11xl: 30px; }

html {
  height: 100%; }

html body {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  background-color: #202022; }

html body footer {
  grid-row-start: 2;
  grid-row-end: 3;
  background-color: #202022;
  padding-top: 35px;
  padding-bottom: 70px; }

html body footer > div {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
  font-size: var(--font-size-base);
  font-family: var(--font-inter);
  width: 100vw;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  align-items: start;
  text-decoration: none; }

@media (min-width: 600px) {
  html body footer > div {
    grid-template-columns: repeat(3, 1fr); } }

html body footer > div > div > div:first-child {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 22px; }

@media (max-width: 599px) {
  html body footer > div > div > div:first-child {
    margin-top: 35px; } }

html body footer > div > div > div:not(:first-child) {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6); }

html body footer > div > div > div:not(:first-child) a {
  color: rgba(255, 255, 255, 0.8); }

html body footer > div > div > div:not(:first-child) a:hover {
  color: white; }

html body footer > div > div:first-of-type {
  text-align: center; }

html body footer > div > div:last-of-type {
  text-align: center; }

html body footer > div .center-text {
  text-align: center; }

.spinner {
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

section#inscription_wizard {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  width: 100vw;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto; }

section#inscription_wizard section#insription_fee_container form {
  width: 100%; }

.satoshis {
  font-weight: 800;
  color: var(--color-darkorange); }

.title {
  font-size: 45pt;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20pt;
  box-sizing: border-box;
  align-items: center;
  justify-content: center; }

.copyright2023Satrepublic {
  position: relative; }

.copyrightTp,
.satsProvidedBy {
  display: flex;
  align-items: center;
  height: 50px; }

.copyrightTp {
  flex-direction: row;
  justify-content: center; }

.satsProvidedBy {
  position: relative;
  width: 136px;
  flex-shrink: 0; }

.image1 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  border-radius: var(--br-xl);
  width: 40px;
  height: 40px;
  background-image: url(/public/image1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top; }

.inscribe,
.rss {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.inscribe {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 834px;
  text-align: left; }

.inscribe {
  position: relative;
  background-color: var(--color-gray-100);
  width: 100%;
  flex-direction: column;
  padding: 0 0 20px;
  box-sizing: border-box;
  align-items: center;
  gap: 34px;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-inter);
  overflow-y: scroll; }

.inscribeTopSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  padding: 0 0 20px;
  max-width: 760px; }

.pizzaCouponCode {
  color: white;
  font-family: var(--font-inter);
  font-weight: bold;
  font-size: 13pt;
  border-color: #f7931a;
  border-radius: var(--br-6xl);
  border-width: 2px;
  border-style: solid;
  height: 20pt;
  flex-direction: row;
  padding: var(--padding-mini) 35px;
  width: 76pt;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: none;
  text-align: center;
  padding-top: 3pt;
  padding-bottom: 3pt;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer; }

.pizzaCouponCodeLink {
  font-weight: bold;
  color: #f7931a; }

.homeButton {
  width: 80pt;
  height: 20pt;
  text-align: center;
  padding-top: 3pt;
  padding-bottom: 3pt;
  border-radius: 20pt;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  color: var(--color-lightgray);
  font-weight: normal;
  font-size: 13pt;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer; }

.homeButton:hover {
  background-color: #555555; }

div.byosInner {
  display: flex;
  flex-direction: column;
  align-items: center; }

@media screen and (max-width: 430px) {
  .title {
    align-self: stretch;
    width: auto;
    flex: 1;
    font-size: 66px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .inscribe {
    max-width: 430px; }
  .satoshis {
    font-weight: 800;
    color: var(--color-darkorange); }
  inscribeTopSection {
    max-width: 760px; } }
