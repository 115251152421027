.image6Icon {
  position: absolute;
  top: 1px;
  left: 0;
  width: 415px;
  height: 569px;
  object-fit: cover;
  display: none; }

.chooseHowYou,
.connectAWallet {
  margin: 0;
  position: absolute;
  display: inline-block; }

.connectAWallet {
  top: 36px;
  left: 30px;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  width: 300px;
  height: 35px; }

.chooseHowYou {
  top: 88px;
  left: 32px;
  font-size: var(--font-size-sm);
  line-height: 20px;
  color: #7e7a82;
  width: 366px;
  height: 50px; }

.walletImageIcon,
.walletTitle {
  position: absolute;
  margin: 0 !important; }

.walletImageIcon {
  top: 20px;
  left: 21px;
  border-radius: var(--br-xl);
  width: 40px;
  height: 40px;
  object-fit: cover;
  z-index: 1; }

.walletTitle {
  top: 23px;
  left: 71px;
  font-size: var(--font-size-xl);
  display: flex;
  font-family: var(--font-inter);
  color: var(--color-white);
  text-align: left;
  align-items: center;
  width: 194px;
  height: 33px;
  flex-shrink: 0;
  z-index: 2; }

.radioButtonSelected {
  position: absolute;
  margin: 0 !important;
  top: 27px;
  right: 30px;
  border-radius: 50%;
  background-color: var(--color-white);
  border: 2px solid var(--color-white);
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  z-index: 3; }

.radioButtonUnselected {
  position: absolute;
  margin: 0 !important;
  top: 27px;
  right: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid var(--color-white);
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  z-index: 3; }

.walletType,
.walletTypeSelected,
.walletTypeUnselected {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid var(--color-white);
  /* Default white border */
  padding: 0;
  background-color: transparent;
  /* Default transparent background */
  margin-bottom: 20px;
  /* Adjust this for the gap between each wallet type */
  width: 353px;
  height: 80px; }

.walletSelector {
  position: relative;
  flex-direction: column;
  align-items: center;
  border-radius: var(--br-xl);
  background-color: #1e1e20;
  width: 415px;
  height: 400px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-inter); }

.walletTypeSelected,
.walletTypeUnselected {
  /* Common styling for selected and unselected states */
  position: relative;
  border-radius: 40px;
  box-sizing: border-box;
  width: 85%;
  height: 80px;
  z-index: 0; }

.walletTypeSelected {
  background-color: #e9973d;
  border: 2px solid #e9973d; }

.walletTypeUnselected {
  background-color: var(--color-gainsboro);
  border: 2px solid var(--color-white); }

.walletOptionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centers the children horizontally */
  position: absolute;
  top: 170px;
  /* Adjust as needed to place below .chooseHowYou */
  width: 100%;
  /* Takes the full width of the parent */
  gap: 10px;
  /* Adjust the gap between each wallet type */ }
