.header {
  font-size: var(--font-size-27xl); }

.subheader {
  display: block;
  font-weight: 700;
  font-size: var(--font-size-7xl);
  margin-top: 60px;
  margin-bottom: 60px; }

li {
  font-size: var(--font-size-5xl);
  text-align: justify;
  margin-bottom: 40px;
  font-weight: 300; }
